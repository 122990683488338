import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox`}</h1>
    <p>{`Checkbox, ou caixa de seleção, é utilizado quando há uma lista de opções e o usuário pode selecionar múltiplas escolhas, incluindo todas ou nenhuma delas. Cada checkbox é independente de todos os outros na lista, então selecionar um checkbox não implica no status dos outros itens.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Por padrão, os checkboxes não vem selecionados.`}</li>
      <li parentName="ul">{`A seleção deve acontecer tanto pelo clique diretamente na caixa ou pelo clique na label.`}</li>
      <li parentName="ul">{`As labels devem ser posicionadas à direita da caixa de seleção.`}</li>
      <li parentName="ul">{`Para listas com mais de 4 opções, considere utilizar o `}<a parentName="li" {...{
          "href": "/components/multi-select"
        }}>{`Multi-Select`}</a>{`.`}</li>
    </ul>
    <Demo src='pages/components/checkbox/Checkbox' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      